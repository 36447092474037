import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  IconButton,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import './NavBar.css';
import theme from '../../theme';
import avatar from '../../resources/Selfie.png';

export default function NavBar() {
  return (
    <ThemeProvider theme={theme}>
      <AppBar position='fixed' color='primary'>
        <Toolbar>
          <IconButton>
            <Avatar alt='David Kruggel' src={avatar} style={{ margin: 1 }} />
          </IconButton>
          <Typography variant='h6' color='textPrimary'>
            David Kruggel | Software Developer
          </Typography>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}
