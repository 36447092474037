import React from 'react';
// import logo from '../../logo.svg';
import './App.css';
import { Container } from '@material-ui/core';
import NavBar from '../NavBar/NavBar';
import Cards from '../Cards/Cards';
import Footer from '../Footer/Footer';
import BarChart from '../BarChart/BarChart';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      info: [
        {
          title: '.NET Development',
          description:
            'C# & VB.NET experience using WPF, .NET Framework 4.x and .NET Core 3.x',
        },
        {
          title: 'Web Development',
          description:
            'HTML5, CSS3, JS, React, Material UI, Bootstrap, React Bootstrap',
        },
      ],
    };
  }

  render() {
    return (
      <div className='App'>
        <div className='App-header'>
          <div>
            <NavBar />
          </div>
        </div>
        <div id='body'>
          <section>
            <Container maxWidth='lg'>
              <Cards info={this.state.info} />
            </Container>
          </section>
          <section id='charts'>
            <BarChart />
          </section>
        </div>
        <div id='footer'>
          <Footer />
        </div>
      </div>
    );
  }
}
