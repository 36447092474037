import React from 'react';
import './Cards.css';
import CardUI from '../Card/Card';
import AboutMe from '../AboutMe/AboutMe';

export default class Cards extends React.Component {
    render() {
        return(
            <div className="Cards">
                {
                    this.props.info.map(info => {
                        return <CardUI title={info.title} description={info.description} link={info.link} linkDesc={info.linkDesc} />
                    })
                }
                <AboutMe />
            </div>
        );
    }
}