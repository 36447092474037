import React from 'react';
import { Card, Typography, CardContent, ThemeProvider, IconButton, Button } from '@material-ui/core';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import './Card.css';
import theme from '../../theme';

export default class CardUI extends React.Component {
    render(){
        return(
            <ThemeProvider theme={theme}>
                <Card className="Card" raised="true">
                    <CardContent>
                        <Typography color="textPrimary" variant="h4" gutterBottom>
                            {this.props.title}
                        </Typography>
                        <Typography color="textPrimary" variant="subtitle1" gutterBottom>
                            {this.props.description}
                        </Typography>
                        <IconButton color="primary" href='https://github.com/dkruggel' target='_blank'>
                            <GitHubIcon fontSize="medium" />
                        </IconButton>
                        <IconButton color="primary" href='https://www.linkedin.com/in/david-kruggel-ba56aa1b/' target='_blank'>
                            <LinkedInIcon fontSize="medium" />
                        </IconButton>
                        <Button variant="contained" color="primary" size="small" href="mailto:david.kruggel@gmail.com" target='_blank'>
                            Contact Me!
                        </Button>
                    </CardContent>
                </Card>
            </ThemeProvider>
        )
    };
};