import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import NewNavBar from '../NavBar/NewNavBar';

export default function Note() {
  const [date, setDate] = useState(null);
  const [yesterday, setYesterday] = useState(null);
  const [today, setToday] = useState(null);
  const [gobacks, setGobacks] = useState(null);
  const [impediments, setImpediments] = useState(null);

  let urlParams = useParams();

  useEffect(() => {
    fetch(`https://davidkruggel.herokuapp.com/note/${urlParams.date}`)
      .then((response) => response.json())
      .then((data) => {
        setDate(`${data.Date}`);
        setYesterday(`${data.Yesterday}`);
        setToday(`${data.Today}`);
        setGobacks(`${data.GoBacks}`);
        setImpediments(`${data.Impediments}`);
      });
  }, [urlParams]);

  return (
    <>
    <NewNavBar />
      <h1>{date}</h1>
      <div>Yesterday: {yesterday}</div>
      <div>Today: {today}</div>
      <div>Go Backs: {gobacks}</div>
      <div>Impediments: {impediments}</div>
    </>
  );
}
