import React from 'react';
import { Paper, Typography, makeStyles } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';

const useStyles = makeStyles({
    icon: {
        paddingTop: 8
    },
    text: {
        paddingLeft: 8
    }
})

export default function Footer() {
    const classes = useStyles();

    return (
        <Paper variant="elevation" elevation={4}>
            <Typography className={classes.text}>
                Made with <FavoriteIcon className={classes.icon} fontSize="small" color="primary" /> in O'Fallon, MO
            </Typography>
        </Paper>
    );
}