import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import Status from './components/Status/Status';
import Weather from './components/Weather/Weather';
import Note from './components/Note/Note';
import Notes from './components/Notes/Notes';
import * as serviceWorker from './serviceWorker';
import theme from './theme';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />} />
        <Route path='status' element={<Status />} />
        <Route path='weather' element={<Weather />} />
        <Route path='notes' element={<Notes />} />
        <Route path='note/:date' element={<Note />} />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
