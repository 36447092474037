import React from 'react';
import { useEffect, useState } from 'react';
import NewNavBar from '../NavBar/NewNavBar';

export default function Status() {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch('https://davidkruggel.herokuapp.com/status')
      .then((response) => response.json())
      .then((data) => setData(data));
  }, []);

  return (
    <div>
      <NewNavBar Page='Status' />
      <div>{data}</div>
    </div>
  );
}
