import { Chart } from 'react-google-charts';
import React from 'react';
import { Box } from '@material-ui/core';

const getYears = (begin) => {
  const d1 = new Date(Date.now()).getTime();
  const d2 = new Date(Date.parse(begin)).getTime();
  const diff = (d1 - d2) / 1000 / 3600 / 24 / 365;
  // console.log(diff);
  return diff;
};

const options = {
  title: 'Years of Experience by Technology',
  hAxis: { title: 'Years of Experience', viewWindow: { min: 0, max: 10 } },
  vAxis: { title: 'Technology', viewWindow: { min: 0, max: 7 } },
  legend: 'none',
};

const data = [
  ['tech', 'years', { role: 'style' }],
  ['MSSQL', getYears('06/01/2014'), '#42a5f5'],
  ['VB.NET', getYears('06/01/2014'), '#42a5f5'],
  ['C#', getYears('11/01/2018'), '#42a5f5'],
  ['HTML5', getYears('03/01/2019'), '#42a5f5'],
  ['CSS3', getYears('03/01/2019'), '#42a5f5'],
  ['JavaScript', getYears('03/01/2019'), '#42a5f5'],
  ['React', getYears('02/01/2020'), '#42a5f5'],
];

export default function BarChart() {
  return (
    <Box display='flex' justifyContent='center' width='80%'>
      <Chart
        chartType='BarChart'
        data={data}
        options={options}
        width='100%'
        height='600px'
        legendToggle
        className='chart'
      />
    </Box>
  );
}
