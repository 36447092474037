import React from 'react';
import { Card, ThemeProvider, CardContent, Typography } from '@material-ui/core';
import './AboutMe.css';
import theme from '../../theme';

export default function AboutMe() {
    return(
        <ThemeProvider theme={theme}>
            <Card className="Card" raised="true">
                <CardContent>
                    <Typography color="textPrimary" variant="h4" gutterBottom>
                        About Me
                    </Typography>
                    <Typography color="textPrimary" variant="body1" gutterBottom>
                        I am a developer with a love for web technologies and a long history with .NET desktop application development. When I'm not solving
                        problems or writing awesome applications, I enjoy spending time with my wife and children, taking care of my lawn, and watching YouTube videos.
                    </Typography>
                </CardContent>
            </Card>
        </ThemeProvider>
    );
}