import { createMuiTheme } from '@material-ui/core/styles';
import { blue, grey } from '@material-ui/core/colors'

const theme = createMuiTheme({
    spacing: 2,
    palette: {
        primary: blue,
        secondary:  grey,
        error: {
            main: '#ff0000'
        },
        text: {
            primary: '#212121',
            secondary: '#eee',
            disabled: '#9e9e9e'
        },
        background: {
            paper: grey[400]
        },
    }
});

export default theme;