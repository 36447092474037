import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NewNavBar from '../NavBar/NewNavBar';

export default function Notes() {
  const [date, setDate] = useState([]);

  useEffect(() => {
    fetch(`https://davidkruggel.herokuapp.com/notes`)
      .then((response) => response.json())
      .then((data) => {
        setDate(data);
      });
  }, []);

  return (
    <>
      <NewNavBar />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {Object.entries(date)
          .map((d, index) => {
            return (
              <Link
                to={`/note/${d[1].Date}`}
                key={index}
                style={{
                  fontSize: '2rem',
                  paddingBottom: '1rem',
                  paddingTop: '1rem',
                  textDecoration: 'none',
                }}
              >
                {d[1].Date}
              </Link>
            );
          })
          .sort()
          .reverse()
          .slice(0, 10)}
      </div>
    </>
  );
}
