function kelvinToFahrenheit(k, d = 2) {
  return (((k - 273.15) * 9) / 5 + 32).toFixed(d);
}

function kelvinToCelsius(k, d = 2) {
  return (k - 273.15).toFixed(d);
}

export function utcToLocal(t) {
  var d = new Date(t * 1000).getHours();
  return d;
}

export function utcToLocalFormatted(t) {
  var d = new Date(t * 1000).getHours();
  var m = new Date(t * 1000).getMinutes();
  return `${d}:${m}`;
}

export { kelvinToCelsius, kelvinToFahrenheit };
