import { Card, Paper, Container } from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';
import { utcToLocalFormatted } from '../../utilities/Functions';
import NewNavBar from '../NavBar/NewNavBar';

export default function Weather() {
  const [temp, setTemp] = useState(null);
  const [realFeel, setRealFeel] = useState(null);
  const [sunrise, setSunrise] = useState(null);
  const [sunset, setSunset] = useState(null);

  useEffect(() => {
    fetch('https://davidkruggel.herokuapp.com/weather')
      .then((response) => response.json())
      .then((data) => {
        setTemp(`${data.current.temp.toFixed(2)}°C`);
        setRealFeel(`${data.current.feels_like.toFixed(2)}°C`);
        setSunrise(utcToLocalFormatted(data.current.sunrise));
        setSunset(utcToLocalFormatted(data.current.sunset));
      });
  }, []);

  return (
    <>
      <NewNavBar Page='Weather' />
      <Container maxWidth='sm'>
        <Paper elevation={4}>
          <Card>
            <Container>
              <div>Now: {temp}</div>
              <div>Feels Like: {realFeel}</div>
              <div>Sunrise: {sunrise}</div>
              <div>Sunrise: {sunset}</div>
            </Container>
          </Card>
        </Paper>
      </Container>
    </>
  );
}
